import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { LoadingSpinner } from 'hds-react';
import PlaygroundPreview from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/loading-spinner",
  "title": "LoadingSpinner",
  "navTitle": "LoadingSpinner"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}>{`Example`}<a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <LoadingSpinner mdxType="LoadingSpinner" />
    </PlaygroundPreview>
    <p>{`The loading spinner do not provide details about nature of the processing but it reassures the user that their action is being processed.`}</p>
    <h3 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h3" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Use the loading spinner only if the wait time is anticipated to be longer than three seconds.`}</strong>{` A spinner that only quickly flashes in view can confuse the user.`}</li>
      <li parentName="ul">{`Display the loading spinner in the same area and contextual level where the processing is taking place. For example, if only the loader indicates that data is being retrieved for a specific content area, display the spinner in that same area.`}</li>
      <li parentName="ul">{`If possible, provide a descriptive visual label for the action being processed with the loading spinner.`}</li>
    </ul>
    <h3 {...{
      "id": "variations",
      "style": {
        "position": "relative"
      }
    }}>{`Variations`}<a parentName="h3" {...{
        "href": "#variations",
        "aria-label": "variations permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "default",
      "style": {
        "position": "relative"
      }
    }}>{`Default`}<a parentName="h4" {...{
        "href": "#default",
        "aria-label": "default permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`The loading spinner comes in two sizes:`}</p>
    <ul>
      <li parentName="ul">{`Medium (64px) is used for larger content areas and for full-screen loading`}</li>
      <li parentName="ul">{`Small (24px) can be used for indicating more local changes in content.`}</li>
    </ul>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <LoadingSpinner mdxType="LoadingSpinner" />
  <br />
  <LoadingSpinner small mdxType="LoadingSpinner" />
    </PlaygroundPreview>
    <h4 {...{
      "id": "customized-loading-spinner",
      "style": {
        "position": "relative"
      }
    }}>{`Customized loading spinner`}<a parentName="h4" {...{
        "href": "#customized-loading-spinner",
        "aria-label": "customized loading spinner permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`The colour of the spinner can be customised to fit the colour theme of the service. HDS Loading spinner even supports shifting between multiple colours. Colour shifting should be used sparingly and only if the theme of the service calls for it.`}</p>
    <p>{`Note! When using multicolour loading spinners, at least half of the colours must have sufficient contrast `}<inlineCode parentName="p">{`3:1`}</inlineCode>{` against the spinner background.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <LoadingSpinner theme={{
        '--spinner-color': 'var(--color-tram)'
      }} mdxType="LoadingSpinner" />
  <br />
  <LoadingSpinner multicolor theme={{
        '--spinner-color-stage1': 'var(--color-coat-of-arms)',
        '--spinner-color-stage2': 'var(--color-tram)',
        '--spinner-color-stage3': 'var(--color-metro)'
      }} mdxType="LoadingSpinner" />
    </PlaygroundPreview>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      